import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import yasausa from '../../img/yasausa_contact.png';

import './index.scss';

export default () => (
  <Layout>
    <Helmet>
      <title>Contact | STINGS</title>
      <meta property="og:title" content="Contact | STINGS" />
      <meta name="twitter:title" content="Contact | STINGS"></meta>
    </Helmet>
    <div className="content">
      <div className="content__inner">
        <section className="contact">
          <div className="section-heading-blue">
            <h2>CONTACT</h2>
          </div>
          <div className="contact-content">
            <p>メッセージの送信が完了しました</p>
            <img src={yasausa} alt="Yasausa" />
          </div>
        </section>
      </div>
    </div>
  </Layout>
);
